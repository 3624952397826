import tw from "twin.macro"

export { StyledButton } from "../../styled/Button"
export { StyledColumn as Column } from "../../styled/Column"
export { StyledContainer as Container } from "../../styled/Container"
export { StyledResponsive as Responsive } from "../../styled/Responsive"

export {
  StyledStepsH1 as StepsH1,
  StyledStepsHeadingInner as StepsHeadingInner,
  StyledStepsP as StepsP,
  StyledStepsBg as StepsBg,
  StyledCloseButton as Close,
} from "../../styled/Steps"

export const Text = tw.div`md:text-xs mt-6`

export const Plans = tw.div`px-4 md:px-0 mb-12`
