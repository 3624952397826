import React from "react"
import { graphql } from "gatsby"

import { withoutMembership } from "../hoc/withMember"
import { Memberships as Page } from "../components/Memberships/Memberships"

export const query = graphql`
  query {
    page: sanityPageMemberships {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
    memberships: allSanityMembership {
      edges {
        node {
          ...GatsbyMembershipFragment
        }
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
