import React from "react"

import { withMemberships } from "./withMemberships"
import { CardMembership } from "../Cards/CardMembership"
import { Icon } from "../Icon/Icon"
import { Title } from "../Title/Title"
import { Container, Responsive, StepsH1, StepsHeadingInner, StepsP, StepsBg, Close, Plans } from "./Memberships.styled"

export const Memberships = withMemberships(({ title, content, customer, memberships, code, loading, error, handleCheckout }: Props): JSX.Element => {
  return (
    <Container collapse={`<md`} width={`xl`}>
      <Responsive screen={`<md`}>
        <Title>
          <StepsH1>
            <StepsHeadingInner>{title}</StepsHeadingInner>
            <Close to={`/account/dashboard`}>
              <Icon name={"cross"} size={"s"} colour={"tertiary"} state={`asblock`} />
            </Close>
          </StepsH1>
          {content && <StepsP>{content}</StepsP>}
        </Title>
      </Responsive>

      <Plans>
        {memberships?.map(membership => (
          <CardMembership
            key={membership?.title?.toString()}
            title={membership?.title}
            titleMobile={membership?.title}
            content={membership?.description}
            subcontent={membership?.subcontent}
            coupon={code}
            items={membership?.benefits}
            price={membership?.product?.plan?.amount / 100}
            currency={membership?.product?.plan?.currency?.toUpperCase() || "AUD"}
            buttonText={loading ? "Loading..." : "Join Now"}
            buttonLink={!customer && "/account/register"}
            onClick={() => {
              if (customer) handleCheckout(membership?.product?.plan?.id, membership?.product?.plan?.nickname, code)
            }}
            loading={loading}
            error={error}
          />
        ))}
      </Plans>
    </Container>
  )
})

export interface Props {
  title?: string
  content?: any
  customer?: any
  memberships?: Array<any>
  code?: string
  error?: string
  loading?: boolean
  handleCheckout?: (price: string, type: string, code?: string) => void
}
